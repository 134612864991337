import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import logo from '../assets/images/brand.svg'
import logoFacebook from '../assets/images/social/logo_facebook.svg'
import logoInstagram from '../assets/images/social/logo_instagram.svg'
import logoLinkedin from '../assets/images/social/logo_linkedin.svg'
import logoTwitter from '../assets/images/social/logo_twitter.svg'
import logoYoutube from '../assets/images/social/logo_youtube.svg'
import NavBar from './Navbar'
import NavBarMobile from './NavbarMobile'
import Media from 'react-media';


const Header = ({ props, siteTitle, siteSubtitle, siteEmail, sitePhone }) => (
    <header className="header">
        <div className="grid container">
            <Media queries={{
                small: "(max-width: 767px)",
                large: "(min-width: 768px)"
                }}>
                {matches => (
                    <Fragment>
                        {matches.small && <NavBarMobile />}
                        {matches.large && <NavBar />}
                    </Fragment>
                )}
            </Media>
            <Link
                to="/"
                className="brand">
                <img src={logo} alt="Logo" />
            </Link>
            <div className="social social--lg">
                <ul className="social-icons">
                    <li><a href="https://www.facebook.com/laurawilliamsfitness/" target="_blank" rel="noreferrer"><img src={logoFacebook} alt="Facebook" /></a></li>
                    <li><a href="https://www.instagram.com/laurawilliamsonline/" target="_blank" rel="noreferrer"><img src={logoInstagram} alt="Instagam" /></a></li>
                    <li><a href="https://uk.linkedin.com/in/laura-williams-90369924" target="_blank" rel="noreferrer"><img src={logoLinkedin} alt="LinkedIn" /></a></li>
                    <li><a href="https://twitter.com/laurafitness" target="_blank" rel="noreferrer"><img src={logoTwitter} alt="Twitter" /></a></li>
                    <li><a href="https://www.youtube.com/channel/UC3FBepD7p8lCZg3H7ZygzSw" target="_blank" rel="noreferrer"><img src={logoYoutube} alt="YouTube" /></a></li>
                </ul>
                <a className="social__email" href="mailto:laura@laurawilliamsonline.co.uk">laura@laurawilliamsonline.co.uk</a>
            </div>
        </div>
    </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteEmail: PropTypes.string,
  sitePhone: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  siteEmail: ``,
  sitePhone: ``,
}

export default Header