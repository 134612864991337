import React from "react"

class CookiesNotification extends React.Component {

    constructor(props) {
        super(props);
        this.state = { showCookiesNotification: false }

        if (this.getCookie('hide_cookies_notification') == '') {
            this.state.showCookiesNotification = true;
        }
    };

    hideCookiesNotification() {
        this.setState({ showCookiesNotification: false })
        this.setCookie('hide_cookies_notification', 'true', 360);
    }

    render() {
        return (
            this.state.showCookiesNotification && <div className="cookies-notification">
                This website uses cookies. By continuing to browse the site you are agreeing to our <a href="/privacy-policy" class="privacy-policy-link">privacy policy</a>.
                <a className="btn btn--primary accept-button" onClick={this.hideCookiesNotification.bind(this)}>
                    Accept
                </a>
            </div>
        )
    }

    getCookie(cname) {
        let name = cname + "=";
        if (typeof document !== `undefined`) {
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
        }
        return "";
    }

    setCookie(cname, cvalue, exdays) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        if (typeof document !== `undefined`) {
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }
    }

}

export default CookiesNotification
