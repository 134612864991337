/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './Header'
import Footer from './Footer'
import '../assets/sass/style.scss'
import CookiesNotification from './CookiesNotification'


const Layout = ({ children }) => {
    const metadata = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                    email
                    phone
                    address {
                        line1
                        line2
                        line3
                        line4
                        line5
                    }
                }
            }
        }
    `)

    return (
        <div className="main-wrapper">
            <Header siteTitle={metadata.site.siteMetadata?.title || `Title`} />

            <main className="main">
                {children}
            </main>
        
            <Footer
                siteTitle={metadata.site.siteMetadata.title}
                siteAddress={metadata.site.siteMetadata.address}
                siteEmail={metadata.site.siteMetadata.email}
                sitePhone={metadata.site.siteMetadata.phone}
            />

            <CookiesNotification />
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
