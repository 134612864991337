import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import logo from '../assets/images/brand.svg'
import logoFacebook from '../assets/images/social/logo_facebook.svg'
import logoInstagram from '../assets/images/social/logo_instagram.svg'
import logoLinkedin from '../assets/images/social/logo_linkedin.svg'
import logoTwitter from '../assets/images/social/logo_twitter.svg'
import logoYoutube from '../assets/images/social/logo_youtube.svg'


const Footer = ({ props, siteTitle, siteAddress, siteEmail, sitePhone }) => (
    <footer className="footer">
        <div className="footer__inner container">
            <p className="footer__copyright">
                <a href="/privacy-policy" title="Privacy Policy">Privacy Policy</a><br />
                &copy; 2021 Laura Williams
            </p>
            <Link
                to="/"
                className="brand">
                <img src={logo} alt="Logo" />
            </Link>
            <ul className="social-icons social-icons--lg">
                <li><a href="https://www.facebook.com/laurawilliamsfitness/" target="_blank" rel="noreferrer"><img src={logoFacebook} alt="Facebook" /></a></li>
                <li><a href="https://www.instagram.com/laurawilliamsonline/" target="_blank" rel="noreferrer"><img src={logoInstagram} alt="Instagam" /></a></li>
                <li><a href="https://uk.linkedin.com/in/laura-williams-90369924" target="_blank" rel="noreferrer"><img src={logoLinkedin} alt="LinkedIn" /></a></li>
                <li><a href="https://twitter.com/laurafitness" target="_blank" rel="noreferrer"><img src={logoTwitter} alt="Twitter" /></a></li>
                <li><a href="https://www.youtube.com/channel/UC3FBepD7p8lCZg3H7ZygzSw" target="_blank" rel="noreferrer"><img src={logoYoutube} alt="YouTube" /></a></li>
            </ul>
        </div>
    </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
  siteAddress: PropTypes.object,
  siteEmail: PropTypes.string,
  sitePhone: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
  siteAddress: ``,
  siteEmail: ``,
  sitePhone: ``,
}

export default Footer