import React, { Component, Fragment } from "react"
import { Link } from "gatsby"
import OffCanvas from "react-aria-offcanvas"
import navIcon from "../assets/images/nav_icon.svg"
import navClose from "../assets/images/nav_close.svg"
import logoFacebook from "../assets/images/social/logo_facebook.svg"
import logoInstagram from "../assets/images/social/logo_instagram.svg"
import logoLinkedin from "../assets/images/social/logo_linkedin.svg"
import logoTwitter from "../assets/images/social/logo_twitter.svg"
import logoYoutube from "../assets/images/social/logo_youtube.svg"

const Navigation = () => (
  <nav id="menu">
    <ul>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/about">About Laura</Link>
      </li>
      <li>
        <Link to="/classes">Classes &amp; Training</Link>
      </li>
      <li>
        <Link to="/journalism">Journalism</Link>
      </li>
      <li>
        <Link to="/community">Community</Link>
      </li>
      <li>
        <Link to="/contact">Contact</Link>
      </li>
    </ul>
    <div className="social social--sm">
      <ul className="social__icons">
        <li>
          <a
            href="https://www.facebook.com/laurawilliamsfitness/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={logoFacebook} alt="Facebook" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/laurawilliamsonline/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={logoInstagram} alt="Instagam" />
          </a>
        </li>
        <li>
          <a
            href="https://uk.linkedin.com/in/laura-williams-90369924"
            target="_blank"
            rel="noreferrer"
          >
            <img src={logoLinkedin} alt="LinkedIn" />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/laurafitness"
            target="_blank"
            rel="noreferrer"
          >
            <img src={logoTwitter} alt="Twitter" />
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/channel/UC3FBepD7p8lCZg3H7ZygzSw"
            target="_blank"
            rel="noreferrer"
          >
            <img src={logoYoutube} alt="YouTube" />
          </a>
        </li>
      </ul>
      <a
        className="social__email"
        href="mailto:laura@laurawilliamsonline.co.uk"
      >
        laura@laurawilliamsonline.co.uk
      </a>
    </div>
  </nav>
)

const styles = {
  container: {
    textAlign: "center",
    marginTop: "2.5rem",
    fontFamily:
      ' -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  },
  menu: {
    position: "absolute",
    top: "50%",
    left: "10px",
    transform: "translateY(-50%)",
    zIndex: "1",
    width: "48px",
    height: "48px",
    padding: "0 10px",
    fontSize: "9px",
    fontWeight: "bold",
    color: "#fff",
    appearance: "none",
    border: "0",
    backgroundColor: "#F93721",
  },
  close: {
    position: "fixed",
    display: "flex",
    alignSelf: "flex-start",
    width: "40px",
    height: "40px",
    padding: "7px",
    marginTop: "5px",
    appearance: "none",
    border: "0",
    backgroundColor: "transparent",
  },
  overlay: {
    height: "100vh",
    backgroundColor: "rgba(255,0,0,0.5)",
  },
  content: {
    position: "fixed",
    zIndex: "1000",
    display: "flex",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    padding: "10px 15px",
    overflowY: "auto",
    outline: 0,
  },
}

export default class App extends Component {
  state = {
    isOpen: false,
  }

  open = () => {
    this.setState({ isOpen: true })
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  render() {
    return (
      <Fragment>
        <button
          id="menu-button"
          aria-label="Menu"
          aria-controls="menu"
          aria-expanded={this.state.isOpen}
          onClick={this.open}
          style={styles.menu}
        >
          menu
          <img src={navIcon} alt="Menu" />
        </button>
        <OffCanvas
          isOpen={this.state.isOpen}
          onClose={this.close}
          labelledby="menu-button"
          position="left"
          width="100%"
          height="100%"
          mainContainerSelector=".main-wrapper"
          style={styles}
        >
          <button onClick={this.close} style={styles.close}>
            <img src={navClose} alt="Close Menu" />
          </button>
          <Navigation />
        </OffCanvas>
      </Fragment>
    )
  }
}
